import React from 'react'
import BackgroundCover from 'app/theme/BackgroundCover'
import img from 'assets/img/intro.jpg'
import styles from './Hero.module.css'

export default function Hero(){
  return(
    <BackgroundCover
      imageUrl={img}
      className={styles.heroImage}
    >
      <div className={[
          styles.container,
          'py-12 md:py-20 lg:py-24 text-white']}
        >
        <div className="container flex lg:justify-end">
          <div className="lg:w-1/3">
            <h1 className="text-6xl font-platform leading-none mb-4">
              The Booking Platform for Mobile Food Vendors
            </h1>
            <p className="text-2xl mb-6 leading-tight">
              Fill up your calendar with paid catering and vending opportunities.
            </p>
            <a
              href="/get-started"
              children="Get Started"
              className="btn btn-blue px-5 py-2 text-xl font-bold"
            />
            <a
              href="/login"
              children="Login"
              className="btn btn-white border-none text-blue px-5 py-2 ml-3 text-xl font-bold"
            />
          </div>
        </div>
      </div>
    </BackgroundCover>
  )
}
