import React from 'react'
import styles from './ContentGrid.module.css'

export function ContentGrid(props) {
  return (
    <div className="container mt-12">
      {props.children}
    </div>
  )
}

export function ContentGridRow(props) {
  return (
    <div className={["flex flex-wrap", (props.reverse ? "flex-row-reverse" : "flex-row")]}>
      <div className="flex flex-auto w-full md:w-1/2 py-4 md:p-4 items-center justify-center">
        <img src={props.image} alt={props.heading} className={[props.imageClassName, "block max-w-full"]} />
      </div>
      <div className="flex flex-auto w-full md:w-1/2 py-4 lg:py-0 items-center justify-center">
        <div className={styles.grid_item_textbox}>
          <h3 className="text-navy text-3xl font-platform leading-none mb-5">
            {props.index ? (`${props.index}.`) : ""} {props.heading}
          </h3>
          <div className="font-light text-black text-md">
            {props.children || props.content}
          </div>
        </div>
      </div>
    </div>
  )
}
