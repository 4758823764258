import React from 'react'
import img from 'assets/img/HowItWorks.jpg'
import styles from './HowItWorks.module.css'

export default function HowItWorks(){
  return(
    <section className="py-20 bg-white">
      <div className="container">
        <h2 className="font-platform text-navy text-5xl text-center">
          How Mobile Food Alliance Works
        </h2>
        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/2 px-4 mb-4">
            <img src={img} alt="How It Works" />
          </div>
          <div className="md:w-1/2 px-4 flex flex-col justify-center">
            <div className="list flex mb-5">
              <div className={styles.circleNumber}>1</div>
              <div className="text">
                <h4 className="text-2xl text-navy leading-none">
                  Join the Mobile Food Alliance Marketplace
                </h4>
              </div>
            </div>
            <div className="list flex mb-5">
              <div className={styles.circleNumber}>2</div>
              <div className="text">
                <h4 className="text-2xl text-navy leading-none">
                  Set up your menu and catering packages. We'll set up your profile.
                </h4>
              </div>
            </div>
            <div className="list flex mb-5">
              <div className={styles.circleNumber}>3</div>
              <div className="text">
                <h4 className="text-2xl text-navy leading-none">
                  Connect your calendar and bid on catering opportunities
                </h4>
              </div>
            </div>
            <div className="list flex mb-5">
              <div className={styles.circleNumber}>4</div>
              <div className="text">
                <h4 className="text-2xl text-navy leading-none">
                  We'll let you know when you have catering opportunities or get booked for an event
                </h4>
              </div>
            </div>
            <div className="list flex mb-5">
              <div className={styles.circleNumber}>5</div>
              <div className="text">
                <h4 className="text-2xl text-navy leading-none">
                  Get support from our team whenever you need it
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
