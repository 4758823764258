import React from 'react'
import arrowIcon from 'assets/img/icon-arrow.png'
import dollarIcon from 'assets/img/icon-dollar.png'

export default function WhyWorkWithUs(){
  return(
    <section className="py-20">
      <div className="container">
        <div className="flex flex-row flex-wrap">
          <div className="w-full sm:w-1/2 mb-8">
            <img
              src={arrowIcon}
              width="100"
              className="m-auto mb-2"
              alt="Arrow Icon"
            />
            <h2 className="text-5xl font-platform text-navy leading-none mb-6 text-center">
              Booking Marketplace
            </h2>
            <p className="text-gray-900 mb-4 font-light text-lg leading-6 font-national">
              Fill your calendar with new opportunities- private catering, corporate catering, big events, weddings, vending & more.
            </p>
            <ul className="text-gray-900 list-outside list-disc pl-10 font-light text-lg leading-6">
              <li>Get hired for paid catering opportunities</li>
              <li>Book local community events</li>
              <li>Get selected for vending opportunities, festivals and conventions, and exclusive Mobile Food Alliance partnerships</li>
            </ul>
          </div>
          <div className="w-full sm:w-1/2">
            <img
              src={dollarIcon}
              width="100"
              className="m-auto mb-2"
              alt="Dollar Icon"
            />
            <h2 className="text-5xl font-platform text-navy leading-none mb-6 text-center">
              Toolkit for Growth
            </h2>
            <p className="text-gray-900 mb-4 font-light text-lg leading-6 font-national">
              Tap into a growing suite of tools that help you get more customers and grow sales.
            </p>
            <ul className="text-gray-900 list-outside list-disc pl-10 font-light text-lg leading-6">
              <li>Instant Bidding - Set up your account to automatically bid on catering events</li>
              <li>Lead Capture - Grow your catering and event leads on your own website and social channels</li>
              <li>Proposal Manager - Book more events with professional quotes and invoicing</li>
            </ul>
          </div>
          <hr className="w-full h-px text-gray-900 border-top border-solid my-4" />
        </div>
      </div>
    </section>
  )
}
