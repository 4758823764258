import React from 'react'

export default function BackgroundCover(props) {
  const { src, imageUrl = src, className, style, ...rest } = props
  return (
    <div
      {...rest}
      style={{
        backgroundImage: `url(${imageUrl})`,
        ...style,
      }}
      className={[
        className,
        'bg-cover bg-no-repeat',
      ]}
    />
  )
}
