import React from 'react'
import styles from './Testimonials.module.css'

export default function Testimonials() {
  return (
    <section className="pb-20 px-10 container">
      <div className="quote w-full md:w-1/2 m-auto">
        <blockquote className={styles.quoteBlock}>
          <div className={styles.quoteBg}></div>
          <div className={styles.quoteTriangle}></div>
          <div className="relative text-base md:text-2xl font-light leading-relaxed">
          Mobile Food Alliance puts me in front of customers who want great food cooked fresh. They helped me grow my catering business by 25%. We love working with them!
          </div>
        </blockquote>
        <p className={[styles.byLine, 'italic font-light text-gray-900']}>
          <span className="text-4xl">Dennis</span>
          <br />
          Owner of Big D’s Grub Truck
        </p>
      </div>
    </section>
  )
}
