import React from 'react'
import { ContentGrid, ContentGridRow } from 'app/theme/ContentGrid'
import moduleGridImg1 from 'assets/img/homepage_grid_1.jpg'
import moduleGridImg2 from 'assets/img/homepage_grid_2.jpg'
import moduleGridImg3 from 'assets/img/homepage_grid_3.jpg'

export default function ContentGrids(){
  return (
    <section className="pb-20 pt-8">
      <div className="container text-lg">
        <ContentGrid>
          <ContentGridRow
            heading="How Much Does It Cost?"
            image={moduleGridImg1}
            imageClassName="my-12"
            reverse
          >
            <p className="text-gray-900 mb-4">
              Here’s how it all breaks down:
            </p>
            <ul className="text-gray-900 list-outside list-disc pl-10 font-light">
              <li>Catering: Bid what you want and get 100% of your bid. No fees or deductions.</li>
              <li>Most vending events are free to participate. For some there is a revenue share model with you</li>
              <li>All software tools are free to use. (We add a service fee to the host)</li>
            </ul>
          </ContentGridRow>
          <ContentGridRow
            heading="We Guarantee Everything"
            image={moduleGridImg3}
            imageClassName="my-12"
          >
            <p className="text-gray-900 mb-4">
              There’s nothing better than having peace of mind, that’s why we created a system that protects both you and your clients.
            </p>
            <ul className="text-gray-900 list-outside list-disc pl-10 font-light">
              <li>Get paid even when the client cancels</li>
              <li>We can help you find a replacement if you need to cancel</li>
              <li>Offer our signature 100% Mobile Food Alliance Guarantee to every catering client</li>
            </ul>
          </ContentGridRow>
          <ContentGridRow
            heading="Access to Exclusive Promotions"
            image={moduleGridImg2}
            imageClassName="my-12"
            reverse
          >
            <p className="text-gray-900 mb-4">
              We're always looking for partnerships to get you revenue opportunities and discounts. 
            </p>
            <ul className="text-gray-900 list-outside list-disc pl-10 font-light">
              <li>Go beyond catering and events with food truck promotions and marketing opportunities</li>
              <li>Get access to exclusive brand contests and partner discounts</li>
            </ul>
          </ContentGridRow>
        </ContentGrid>
      </div>
    </section>
  )
}
