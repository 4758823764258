import React from 'react'
import { Redirect } from 'react-router-dom'
import { Layout } from 'app/theme/layout'
import { Helmet } from 'react-helmet'
import Hero from './catering-leads-homepage/Hero'
import WhyWorkWithUs from './catering-leads-homepage/WhyWorkWithUs'
import Testimonials from './catering-leads-homepage/Testimonials'
import HowItWorks from './catering-leads-homepage/HowItWorks'
import ContentGrids from './catering-leads-homepage/ContentGrids'
import Occasions from './catering-leads-homepage/Occasions'
import Features from './catering-leads-homepage/Features'

export default function CateringLeadsHomePage({ session }) {
  if (session.isLoggedIn) {
    return <Redirect to="/catering" />
  }

  return (
    <Layout>
      <Helmet>
        <title>Vendor Portal - Mobile Food Alliance</title>
        <meta name="description" content="Mobile Food Alliance provides growth opportunities for your mobile business with our Booking Marketplace and Toolkit." />
        <meta name="og:title" content="Vendor Portal - Mobile Food Alliance"></meta>
      </Helmet>

      <Hero />
      <WhyWorkWithUs />
      <Testimonials />
      <HowItWorks />
      <ContentGrids />
      <Occasions />
      <Features />
    </Layout>
  )
}
